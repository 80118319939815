"use strict"

import Grid from "@material-ui/core/Grid"
import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Image from "../components/parts/image"
import SEO from "../components/parts/seo"
import { Spacer } from "../components/parts/styled"
import { SubTitle, Title } from "../components/parts/Title"
import { RandNum } from "../components/parts/utils"
import theme from "../theme"

// ###################################
const Products = () => {
  const products = useStaticQuery(graphql`
    query ProductsQuery {
      allProductsJson {
        edges {
          node {
            txt
            h3 {
              txt
              pdf
              sub {
                txt
                img
                pdf
                sub {
                  txt
                  pdf
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={`製品一覧`} />

      <Spacer v={11} />

      {products.allProductsJson.edges.map((c) => (
        <React.Fragment key={RandNum()}>
          <Title text={c.node.txt} />

          <Spacer v={3} />

          {c.node.h3.map((cc) => (
            <React.Fragment key={RandNum()}>
              {/* eslint-disable-next-line react/no-children-prop */}
              <SubTitle children={`▶ ` + cc.txt.join(` / `)} />

              <Spacer v={3} />

              <Grid container spacing={3}>
                {cc.sub !== null
                  ? cc.sub.map((ccc) => (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        key={RandNum()}
                        style={{ border: `1px solid #CCC` }}
                      >
                        <SubTitle
                          /* eslint-disable-next-line react/no-children-prop */
                          children={
                            <>
                              {ccc.txt.join(` / `)}
                              &nbsp;
                              {ccc.pdf !== null ? (
                                <a href={ccc.pdf} target={`_blank`}>
                                  <PictureAsPdfSharpIcon
                                    style={{ margin: `0 ${theme.prime.sp007}` }}
                                  />
                                </a>
                              ) : (
                                ``
                              )}
                            </>
                          }
                          v={0}
                        />

                        <Spacer v={3} />

                        {ccc.img !== null ? (
                          <Image filename={`products/_${ccc.img}.jpg`} />
                        ) : (
                          <Image filename={`products/_dummy.jpg`} />
                        )}
                        <Spacer v={3} />

                        <div style={{ margin: `0 0 0 11px` }}>
                          {ccc.sub !== null
                            ? ccc.sub.map((cccc) => (
                                <React.Fragment key={RandNum()}>
                                  {cccc.txt[0]}
                                  {cccc.txt[1] !== undefined
                                    ? // eslint-disable-next-line no-irregular-whitespace
                                      `　` + cccc.txt[1]
                                    : ``}
                                  &nbsp;
                                  {cccc.pdf !== null ? (
                                    <a href={cccc.pdf} target={`_blank`}>
                                      <PictureAsPdfSharpIcon
                                        style={{
                                          margin: `0 ${theme.prime.sp007}`,
                                        }}
                                      />
                                    </a>
                                  ) : (
                                    ``
                                  )}
                                  <br />
                                  {cccc.txt[2] !== undefined ? (
                                    <>
                                      {cccc.txt[2]}
                                      <br />
                                    </>
                                  ) : (
                                    ``
                                  )}
                                </React.Fragment>
                              ))
                            : ``}
                        </div>

                        <Spacer v={3} />
                      </Grid>
                    ))
                  : ``}
              </Grid>
              <Spacer v={5} />
            </React.Fragment>
          ))}

          <Spacer v={3} />
        </React.Fragment>
      ))}
    </Layout>
  )
}

export default Products
